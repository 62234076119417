import {
  Button,
  Card,
  DatePicker,
  Input,
  Form,
  InputNumber,
  Select,
  Row,
  message, Radio, Table, Col, Checkbox, TimePicker
} from 'antd';
import React, {useEffect, useState} from 'react';
import styles from './style.less';
import {
  addClassSchedule,
  classAndOneToOneList,
  classScheduleDetail,
  teacherAllList, updateClassSchedule
} from "@/services/tmh/api";
import {RadioChangeEvent} from "antd/lib/radio/interface";
import {PageContainer, ModalForm} from "@ant-design/pro-components";
import {useHistory} from "react-router-dom";
import {closeTabAction, getQuery} from "@/assets/js/publicFunc";
import moment from "moment";

const FormItem = Form.Item;
const {Option} = Select;

interface ClassSchedule {
  mode?: number;
  id?: number;
  teacherId?: number;
  classId?: number;
  repeatMode?: number;
  periodStart?: string;
  periodEnd?: string;
  timePeriod?: string;
  dayOfWeek?: string;
  subject?: string;
  endType?: number;
  remainingLessonCount?: number;
  dateForClass?: string;
}

const ScheduleForm: React.FC = () => {

  const [form] = Form.useForm();

  const [modalForm] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const [load, setLoad] = useState(false);

  const [switchMode, setSwitchMode] = useState(false);

  const [formVisible, setFormVisible] = useState(false);

  const [endTypeVisible, setEndTypeVisible] = useState(false);

  const [teacherMap] = useState(new Map);

  const [tableList, setTableList] = useState({});

  const [modalValues, setModalValues] = useState({});

  const history: CommonObjectType = useHistory();

  const [teacher, setTeacher] = useState([]);

  const [timePeriodMode, setTimePeriodMode] = useState(true);

  const [formTimePeriodMode, setFormTimePeriodMode] = useState(true);

  const [dayOfWeekArr, setDayOfWeekArr] = useState([]);

  const timeFormat = 'HH:mm';

  const {id} = getQuery();

  const [row, setRow] = useState<ClassSchedule>({});

  const getTeacherSelect = async () => {
    try {
      const res = await teacherAllList();
      if (res.code == '0') {
        const temp: any = [];
        teacherMap.clear();
        res.data.map(function (item: any) {
          teacherMap.set(item.id, item.name);
          temp.push(
            <option key={item.id} value={item.id}>
              {`${item.name}`}
            </option>);
        });
        setTeacher(temp);
      }
    } catch (error) {
    }
  }

  const [schoolClass, setSchoolClass] = useState([]);

  const getClassAllList = async () => {
    try {
      const res = await classAndOneToOneList();
      if (res.code == '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(<option key={item.id} value={item.id}>{item.name}</option>);
        });
        setSchoolClass(temp);
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    getTeacherSelect().then();
    getClassAllList().then();
    // 编辑模式
    if (id) {
      getDetail().then();
    } else {
      setLoad(true);
    }
  }, []);

  // 编辑模式，查询排课规则详情
  const getDetail = async () => {
    try {
      const res = await classScheduleDetail({id});
      if (res.code == '0') {
        setRow(res.data);
        let mode = res.data.mode;
        if (mode == 2) {
          setFormTimePeriodMode(false);
          setEndTypeVisible(res.data.endType == 2);
          setSwitchMode(true);
          // 处理表格
          let modeStr = '-';
          if (res.data.repeatMode == 1) {
            modeStr = '每周循环(';
            res.data.dayOfWeek.split(',').map((item) => {
              modeStr += switchWeek(Number(item)) + "、";
            })
            modeStr = modeStr.substr(0, modeStr.length - 1) + ")";
          }
          let periodStr = '';
          if (res.data.endType == 1) {
            periodStr = res.data.periodStart.substr(0, 10) + ' ~ ' + res.data.periodEnd.substr(0, 10);
          } else {
            periodStr = res.data.periodStart.substr(0, 10) + ' ~ -';
          }
          setTimeout(async () => {
              setTableList({
                  modeStr: modeStr,
                  teacherName: teacherMap.get(Number(res.data.teacherId)),
                  periodStr: periodStr,
                  timePeriod: res.data.timePeriod,
                  subject: res.data.subject
                }
              )
            }, 50
          );
          let resArr: number[] = [];
          res.data.dayOfWeek.split(',').map((item) => {
            resArr.push(Number(item));
          });
          setDayOfWeekArr(resArr);
        }else{
          setTimePeriodMode(false);
        }
        setLoad(true);
      }
    } catch (error) {
      console.log(error);
    }
  }


  const change = (e: RadioChangeEvent) => {
    setSwitchMode(!switchMode);
  };

  const onFinish = async (values: { [key: string]: any }) => {
    let argument = values;
    if (argument.timePeriodMode == 2) {
      argument.timePeriod = `${argument.timePeriod1[0].format('HH:mm')}-${argument.timePeriod1[1].format('HH:mm')}`;
    }
    if (values.mode == 2) {
      modalValues['classId'] = values.classId;
      modalValues['mode'] = values.mode;
      argument = modalValues;
      if (argument.timePeriodMode == 2) {
        argument.timePeriod = `${argument.timePeriod1[0].substring(11, 16)}-${argument.timePeriod1[1].substring(11, 16)}`;
      }
    }
    setLoading(true);
    setTimeout(async () => {
      try {
        let data;
        if (row.id) {
          argument.id = row.id;
          console.log(argument);
          data = await updateClassSchedule(argument);
        } else {
          data = await addClassSchedule(argument);
        }
        if (data.code === '0') {
          message.success(data.msg);
          closeTabAction(history, '/eduCenter/schedule');
          return;
        }
      } catch (error) {
      }
    }, 500);
    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    // eslint-disable-next-line no-console
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 10},
  };

  const tailLayout = {
    wrapperCol: {offset: 4, span: 10},
  };

  const formTailLayout = {
    wrapperCol: {offset: 4, span: 12},
  };

  const columns1 = [
    {
      title: '排课方式',
      dataIndex: 'modeStr',
    },
    {
      title: '上课日期',
      dataIndex: 'periodStr',
    },
    {
      title: '上课时段',
      dataIndex: 'timePeriod',
    },
    {
      title: '教师',
      dataIndex: 'teacherName',
    },
    {
      title: '上课主题',
      dataIndex: 'subject',
    },
  ]

  const switchEndType = () => {
    if (!endTypeVisible) {
      return (<Form.Item
        name={'periodEnd'}
        initialValue={row.periodEnd ? moment(row.periodEnd) : undefined}
        noStyle
        rules={[{required: true, message: '截止日期不能为空'}]}
        hidden={endTypeVisible}
      >
        <DatePicker placeholder={'请选择截止日期'}
                    style={{width: '40%'}}
                    defaultValue={row.periodEnd ? moment(row.periodEnd) : undefined}
        />
      </Form.Item>)
    } else {
      return (<Form.Item
        name={'remainingLessonCount'}
        noStyle
        rules={[{required: true, message: '课时节数不能为空'}]}
        hidden={!endTypeVisible}
        initialValue={row.remainingLessonCount ? row.remainingLessonCount : 1}
      >
        <InputNumber min={1} style={{width: '40%'}}
                     defaultValue={row.remainingLessonCount ? row.remainingLessonCount : 1}
        />
      </Form.Item>)
    }
  }

  const formLayout = {
    labelCol: {span: 4},
  };

  const formItemLayout = {
    wrapperCol: {span: 12},
  };

  const switchWeek = (week: number): string => {
    switch (week) {
      case 1 :
        return "周一";
      case 2 :
        return "周二";
      case 3 :
        return "周三";
      case 4 :
        return "周四";
      case 5 :
        return "周五";
      case 6 :
        return "周六";
      case 7 :
        return "周天";
    }
    return '';
  }

  const switchDiv = () => {
    if (!switchMode) {
      return (
        <div>
          <Form.Item
            label={'上课日期'}
            name="freeScheduleDate"
            initialValue={row.dateForClass ? moment(row.dateForClass) : undefined}
            rules={[
              {
                required: true,
                message: '请选择日期',
              },
            ]}
          >
            <DatePicker style={{width: '60%'}}
                        defaultValue={row.dateForClass ? moment(row.dateForClass) : undefined}
            />
          </Form.Item>
          <Form.Item
            label={'上课时段'}
            initialValue={row.id && row.mode == 1 ? 2 : 1}
            name={'timePeriodMode'}
            rules={[
              {
                required: true,
                message: '上课时段不能为空',
              },
            ]}
          >
            <Radio.Group defaultValue={row.id && row.mode == 1 ? 2 : 1} onChange={(e: RadioChangeEvent) => {
              setTimePeriodMode(e.target.value === 1);
            }}>
              <Radio value={1}>
                固定模式
              </Radio>
              <Radio value={2} checked={true}>
                自由模式
              </Radio>
            </Radio.Group>
          </Form.Item>

          {
            timePeriodMode ?
              <Form.Item
                name={'timePeriod'}
                {...tailLayout}
                rules={[
                  {
                    required: true,
                    message: '上课时段不能为空',
                  },
                ]}
              >
                <Select
                  style={{width: '60%'}}
                  placeholder={"请选择上课时段"}
                >
                  <Option value={'08:00-08:50'}>08:00-08:50</Option>
                  <Option value={'09:00-09:50'}>09:00-09:50</Option>
                  <Option value={'10:00-10:50'}>10:00-10:50</Option>
                  <Option value={'11:00-11:50'}>11:00-11:50</Option>
                  <Option value={'12:00-12:50'}>12:00-12:50</Option>
                  <Option value={'13:00-13:50'}>13:00-13:50</Option>
                  <Option value={'14:00-14:50'}>14:00-14:50</Option>
                  <Option value={'15:00-15:50'}>15:00-15:50</Option>
                  <Option value={'16:00-16:50'}>16:00-16:50</Option>
                  <Option value={'17:00-17:50'}>17:00-17:50</Option>
                  <Option value={'18:30-19:20'}>18:30-19:20</Option>
                  <Option value={'19:30-20:20'}>19:30-20:20</Option>
                  <Option value={'20:30-21:20'}>20:30-21:20</Option>
                  <Option value={'21:30-22:20'}>21:30-22:20</Option>
                </Select>
              </Form.Item>
              :
              <Form.Item
                name={'timePeriod1'}
                {...tailLayout}
                rules={[
                  {
                    required: true,
                    message: '上课时段不能为空',
                  },
                ]}
                initialValue={row.id && row.mode == 1 ? [moment(row.timePeriod.split('-')[0], timeFormat), moment(row.timePeriod.split('-')[1], timeFormat)] : undefined}
              >
                <TimePicker.RangePicker format={timeFormat}
                                        defaultValue={row.id && row.mode == 1 ? [moment(row.timePeriod.split('-')[0], timeFormat), moment(row.timePeriod.split('-')[1], timeFormat)] : undefined}
                />
              </Form.Item>
          }
          <Form.Item
            label={'上课教师'}
            name="teacherId"
            initialValue={row.teacherId && row.mode == 1  ? row.teacherId : undefined}
            rules={[
              {
                required: true,
                message: '上课教师不能为空',
              },
            ]}
          >
            <Select
              placeholder={'请选择上课教师'}
              showSearch
              style={{width: '60%'}}
              defaultValue={row.teacherId && row.mode == 1 ? row.teacherId : undefined}
              filterOption={(input, option) =>
                (option.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {teacher}
            </Select>
          </Form.Item>

          <Form.Item
            label={'上课主题'}
            name="subject"
            initialValue={row.id && row.mode == 1 ? row.subject : undefined}
          >
            <Input placeholder="请输入上课主题"
                   style={{width: '60%'}}
                   defaultValue={row.id && row.mode == 1 ? row.subject : undefined}
            />
          </Form.Item>
        </div>

      )
    } else {
      return (
        <div>
          <Row style={{paddingBottom: '20px'}}>
            <Col offset={4} span={2}>
              <Button type="primary" onClick={() => {
                setFormVisible(true)
              }}>
                添加排课
              </Button>
            </Col>
          </Row>
          <Row gutter={[16, {xs: 8, sm: 16, md: 24, lg: 32}]}>
            <Col offset={4} span={20} style={{paddingLeft: '2px'}}>
              <Table
                bordered
                pagination={false}
                columns={columns1}
                dataSource={[tableList]}
              />
            </Col>
          </Row>
        </div>
      )
    }


  }

  return (
    load &&
    <>
      <Form
        style={{marginTop: 8}}
        {...layout}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <PageContainer
        >
          <Card className={styles.card} bordered={true} title={"班级排课"}>
            <FormItem
              label={'班级/1对1'}
              name="classId"
              rules={[
                {
                  required: true,
                  message: '班级/1对1不能为空',
                },
              ]}
              initialValue={row.classId ? row.classId : undefined}
            >
              <Select
                placeholder={'请选择班级/1对1'}
                showSearch
                style={{width: '60%'}}
                defaultValue={row.classId ? row.classId : undefined}
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {schoolClass}
              </Select>
            </FormItem>
            <FormItem
              label={'排课方式'}
              name="mode"
              initialValue={row.mode ? row.mode : 1}
              rules={[
                {
                  required: true,
                  message: '排课方式不能为空',
                },
              ]}
            >
              <Radio.Group
                defaultValue={row.mode ? row.mode : 1}
                onChange={change}>
                <Radio value={1}>
                  自由排课
                </Radio>
                <Radio value={2}>
                  重复排课
                </Radio>
              </Radio.Group>
            </FormItem>
            {switchDiv()}

            <FormItem {...tailLayout} style={{marginTop: 32}}>
              <Button type='default' onClick={() => {
                closeTabAction(history, '/eduCenter/schedule')
              }}>
                取消
              </Button>
              <Button type="primary" htmlType="submit" style={{marginLeft: 24}} loading={loading}>
                提交
              </Button>
            </FormItem>
          </Card>
        </PageContainer>
      </Form>

      <ModalForm
        form={modalForm}
        title={'重复排课'}
        {...formLayout}
        width="780px"
        layout={'horizontal'}
        onVisibleChange={setFormVisible}
        visible={formVisible}
        onFinish={async (values) => {
          let modeStr = '-';
          if (values.repeatMode === 1) {
            modeStr = '每周循环(';
            values.dayOfWeekList?.map((item: number) => {
              modeStr += switchWeek(item) + "、";
            });
            modeStr = modeStr.substr(0, modeStr.length - 1) + ")";
          }
          let periodStr = '';
          if (values.endType === 1) {
            periodStr = values.periodStart.substr(0, 10) + ' ~ ' + values.periodEnd.substr(0, 10);
          } else {
            periodStr = values.periodStart.substr(0, 10) + ' ~ -';
          }
          let timePeriod = '';
          if (formTimePeriodMode) {
            timePeriod = values.timePeriod;
          } else {
            timePeriod = `${values.timePeriod1[0].substring(11, 16)}-${values.timePeriod1[1].substring(11, 16)}`;
          }
          setTableList({
            modeStr: modeStr,
            teacherName: teacherMap.get(Number(values.teacherId)),
            periodStr: periodStr,
            timePeriod: timePeriod,
            subject: values.subject
          });
          setFormVisible(false);
          setModalValues(values);
        }}
      >
        <Row gutter={16} justify="start">
          <Col span={16}>
            <Form.Item
              label={'开始日期'}
              name="periodStart"
              {...formItemLayout}
              initialValue={row.periodStart ? moment(row.periodStart) : undefined}
              rules={[{required: true, message: '开始日期不能为空'}]}
            >
              <DatePicker placeholder={'请选择开始日期'}
                          style={{width: '100%'}}
                          defaultValue={row.periodStart ? moment(row.periodStart) : undefined}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify="start">
          <Col span={16}>
            <Form.Item
              label={'结束方式'}
            >
              <Input.Group compact>
                <Form.Item
                  name="endType"
                  noStyle
                  initialValue={row.endType ? row.endType : 1}
                  rules={[{required: true, message: '结束方式不能为空'}]}
                >
                  <Select defaultValue={row.endType ? row.endType : 1}
                          style={{width: '30%'}}
                          onChange={() => {
                            setEndTypeVisible(!endTypeVisible);
                          }}
                  >
                    <Option value={1}>按日期结束</Option>
                    <Option value={2}>按课时总数</Option>
                  </Select>
                </Form.Item>
                {switchEndType()}
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify="start">
          <Col span={16}>
            <Form.Item
              label="重复方式"
              name='repeatMode'
              initialValue={1}
            >
              <Radio.Group defaultValue={1}>
                <Radio value={1}>
                  每周
                </Radio>
              </Radio.Group>

            </Form.Item>
          </Col>
        </Row>
        <Row justify="start">
          <Col span={16}>
            <Form.Item
              label="周几上课"
              name='dayOfWeekList'
              rules={[{required: true, message: '请选择周几上课'}]}
              initialValue={row.dayOfWeek ? dayOfWeekArr : undefined}
            >
              <Checkbox.Group defaultValue={row.dayOfWeek ? dayOfWeekArr : undefined}>
                <Checkbox value={2}>周二</Checkbox>
                <Checkbox value={3}>周三</Checkbox>
                <Checkbox value={4}>周四</Checkbox>
                <Checkbox value={5}>周五</Checkbox>
                <Checkbox value={6}>周六</Checkbox>
                <Checkbox value={7}>周天</Checkbox>
              </Checkbox.Group>

            </Form.Item>
          </Col>
        </Row>
        <Row justify="start">
          <Col span={16}>
            <Form.Item
              label={'上课时段'}
              initialValue={row.id && row.mode == 2 ? 2 : 1}
              name={'timePeriodMode'}
              rules={[
                {
                  required: true,
                  message: '上课时段不能为空',
                },
              ]}
            >
              <Radio.Group defaultValue={row.id && row.mode == 2 ? 2 : 1} onChange={(e: RadioChangeEvent) => {
                setFormTimePeriodMode(e.target.value === 1);
              }}>
                <Radio value={1}>
                  固定模式
                </Radio>
                <Radio value={2}>
                  自由模式
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start">
          <Col span={16}>
            {
              formTimePeriodMode ?
                <Form.Item
                  name={'timePeriod'}
                  {...formTailLayout}
                  rules={[
                    {
                      required: true,
                      message: '上课时段不能为空',
                    },
                  ]}
                >
                  <Select
                    placeholder={"请选择上课时段"}
                  >
                    <Option value={'08:00-08:50'}>08:00-08:50</Option>
                    <Option value={'09:00-09:50'}>09:00-09:50</Option>
                    <Option value={'10:00-10:50'}>10:00-10:50</Option>
                    <Option value={'11:00-11:50'}>11:00-11:50</Option>
                    <Option value={'12:00-12:50'}>12:00-12:50</Option>
                    <Option value={'13:00-13:50'}>13:00-13:50</Option>
                    <Option value={'14:00-14:50'}>14:00-14:50</Option>
                    <Option value={'15:00-15:50'}>15:00-15:50</Option>
                    <Option value={'16:00-16:50'}>16:00-16:50</Option>
                    <Option value={'17:00-17:50'}>17:00-17:50</Option>
                    <Option value={'18:30-19:20'}>18:30-19:20</Option>
                    <Option value={'19:30-20:20'}>19:30-20:20</Option>
                    <Option value={'20:30-21:20'}>20:30-21:20</Option>
                    <Option value={'21:30-22:20'}>21:30-22:20</Option>
                  </Select>
                </Form.Item>
                :
                <Form.Item
                  name={'timePeriod1'}
                  {...formTailLayout}
                  rules={[
                    {
                      required: true,
                      message: '上课时段不能为空',
                    },
                  ]}
                  initialValue={row.id ? [moment(row.timePeriod.split('-')[0], timeFormat), moment(row.timePeriod.split('-')[1], timeFormat)] : undefined}
                >
                  <TimePicker.RangePicker
                    format={timeFormat}
                    defaultValue={row.id ? [moment(row.timePeriod.split('-')[0], timeFormat), moment(row.timePeriod.split('-')[1], timeFormat)] : undefined}
                  />
                </Form.Item>
            }
          </Col>
        </Row>
        <Row justify="start">
          <Col span={16}>
            <Form.Item
              label={'上课教师'}
              name="teacherId"
              initialValue={row.teacherId && row.mode == 2 ? row.teacherId : undefined}
              rules={[
                {
                  required: true,
                  message: '上课教师不能为空',
                },
              ]}
            >
              <Select
                placeholder={'请选择上课教师'}
                showSearch
                style={{width: '60%'}}
                defaultValue={row.teacherId && row.mode == 2 ? row.teacherId : undefined}
                filterOption={(input, option) =>
                  (option.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {teacher}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start">
          <Col span={16}>
            <Form.Item
              label={'上课主题'}
              name="subject"
              initialValue={row.id && row.mode == 2 ? row.subject : undefined}
            >
              <Input placeholder="请输入上课主题"
                     style={{width: '60%'}}
                     defaultValue={row.id && row.mode == 2 ? row.subject : undefined}
              />
            </Form.Item>
          </Col>
        </Row>
      </ModalForm>
    </>
  );
}
export default ScheduleForm;
